<template>
  <aside>
    <div class="l-side">
      <div class="l-side__upper">
        <div class="l-side__upper__logo">
          <router-link to="/"></router-link>
        </div>
        <div class="l-side__upper__btns" v-if="isGenerator || isCollectProxy || isDisposalProxy">
          <div>
            <router-link v-if="isGenerator" class="c-btn primary-plus sm" to="/manifest/input">
              <span>予約</span>
              <span>登録</span>
            </router-link>
            <router-link v-else-if="isCollectProxy" class="c-btn primary-plus sm" to="/manifest/proxy/input">
              <span>代理</span>
              <span>予約</span>
            </router-link>
          </div>
          <div>
            <router-link v-if="isGenerator" class="c-btn secondary sm" to="/manifest/multipleinput">
              <span>複数</span>
              <span>予約</span>
              <span>登録</span>
            </router-link>
            <router-link v-else-if="isCollectProxy" class="c-btn secondary sm" to="/manifest/proxy/multipleinput">
              <span>複数</span>
              <span>代理</span>
              <span>予約</span>
            </router-link>
          </div>
          <div v-if="isGenerator">
            <router-link to="/manifest/temporaryinput" class="c-btn secondary sm">
              <span>番号</span>
              <span class="sm-none">のみ</span>
              <span>発行</span>
            </router-link>
          </div>
          <div v-if="isGenerator">
            <router-link to="/manifest/csvupload/" class="c-btn secondary sm">
              <span>一括予約CSV取込</span>
            </router-link>
          </div>
          <div v-if="isDisposalProxy">
            <router-link to="/manifest/csvupload/" class="c-btn secondary sm">
              <span>一括登録CSV取込</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="l-side__menuWrap">
        <ul class="l-side__menu" v-if="menu">
          <li
              v-for="(item, index) in menu"
              :key="index"
              class="l-side__menu__each"
              :class="[
              getIconClass(item.name),
              {
                hasChild: item.subMenus,
                active: item.name === 'お知らせ' && user.existsUnread,
              },
            ]"
          >
            <router-link :to="getPath(item)">
              <span class="l-side__menu__each__icon"></span>
              <span class="l-side__menu__each__text">{{ item.name }}</span>
            </router-link>
            <!-- サブメニュー -->
            <ul v-if="item.subMenus && item.subMenus.length" class="l-side__menu__each__child">
              <li
                  v-for="(child, subIndex) in item.subMenus"
                  :key="`sub-menu-${index}-${subIndex}`"
              >
                <router-link :to="child.path">
                  <span class="l-side__menu__each__text">{{ child.name }}</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  data() {
    return {
      menu: this.$store.getters["menu/get"],
      user: this.$store.getters["userInfo/get"],
      isGenerator: this.$store.getters["userInfo/isGenerator"],
      isCba: this.$store.getters["userInfo/isCba"],
      isCollectProxy: this.$store.getters["userInfo/isCollectProxy"],
      isDisposalProxy: this.$store.getters["userInfo/isDisposalProxy"],
      notDisplayMenu: [
        'スケジュール',
        '許可証',
        '事前協議書',
        '共有情報',
        '郵便番号データ',
      ],
      notDisplayPostCode: [
        '郵便番号データ'
      ],
      notDisplaySubMenu:[
        '一括CSV取り込み',
        'CSV登録確認',
      ],
    };
  },
  methods: {
    getPath(menu) {
      if (menu.path) {
        return menu.name === "CBA契約" && this.isGenerator ? menu.path + this.user.groupInfo.id : menu.path;
      } else {
        return menu.subMenus[0].path;
      }
    },
    getIconClass(name) {
      if (name === "ホーム") {
        return "home";
      }
      if (name === "お知らせ") {
        return "news";
      }
      if (name === "スケジュール") {
        return "schedule";
      }
      if (name === "マニフェスト") {
        return "manifest";
      }
      if (name === "委託契約") {
        return "contract";
      }
      if (name === "許可証") {
        return "licence";
      }
      if (name === "ルート管理") {
        return "route";
      }
      if (name === "品目管理") {
        return "manage";
      }
      if (name === "アカウント管理") {
        return "account";
      }
      if (name === "CBA契約") {
        return "cba";
      }
      if (name === "排出事業者") {
        return "generator";
      }
      if (name === "処理事業者") {
        return "disposer";
      }
      if (name === "事前協議書") {
        return "prior";
      }
      if (name === "共有情報") {
        return "shareinfo";
      }
      if (name === "郵便番号データ") {
        return "zipcode";
      }
    },
  },
};
</script>